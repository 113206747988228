// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg3 {
  background: rgb(24, 121, 9);
  background: linear-gradient(
    90deg,
    rgba(24, 220, 9, 0.7035189075630253) 0%,
    rgba(9, 121, 71, 0.7231267507002801) 48%,
    rgba(0, 4, 121, 0.7651435574229692) 100%
  );
}

.txt_1 {
  color: rgb(2, 0, 36);
  color: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 121, 69, 0.7651435574229692) 63%,
    rgba(0, 223, 112, 1) 100%
  );
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}
#app {
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .react-multi-carousel-item {
    width: 300px !important;
  }
}
/* @media screen and (min-width: 370px) {
  .react-multi-carousel-item {
    width: 200px !important;
  }
} */

.react-multi-carousel-list {
  position: static !important;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Root/root.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B;;;;;GAKC;AACH;;AAEA;EACE,oBAAoB;EACpB;;;;;GAKC;AACH;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;AACA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,yDAAyD;EACzD,eAAe;EACf,WAAW;EACX,SAAS;EACT,UAAU;AACZ;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF;AACA;;;;GAIG;;AAEH;EACE,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;;EAEhB,iCAAiC;EACjC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".bg3 {\n  background: rgb(24, 121, 9);\n  background: linear-gradient(\n    90deg,\n    rgba(24, 220, 9, 0.7035189075630253) 0%,\n    rgba(9, 121, 71, 0.7231267507002801) 48%,\n    rgba(0, 4, 121, 0.7651435574229692) 100%\n  );\n}\n\n.txt_1 {\n  color: rgb(2, 0, 36);\n  color: linear-gradient(\n    90deg,\n    rgba(2, 0, 36, 1) 0%,\n    rgba(9, 121, 69, 0.7651435574229692) 63%,\n    rgba(0, 223, 112, 1) 100%\n  );\n}\n\n.container {\n  max-width: 1300px;\n  margin: 0 auto;\n  padding: 0 20px;\n}\n#app {\n  height: 100%;\n}\n\nbody {\n  background: #eee;\n  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;\n  font-size: 14px;\n  color: #000;\n  margin: 0;\n  padding: 0;\n}\n\n@media screen and (min-width: 768px) {\n  .react-multi-carousel-item {\n    width: 300px !important;\n  }\n}\n/* @media screen and (min-width: 370px) {\n  .react-multi-carousel-item {\n    width: 200px !important;\n  }\n} */\n\n.react-multi-carousel-list {\n  position: static !important;\n}\n.swiper {\n  width: 100%;\n  height: 100%;\n}\n\n.swiper-slide {\n  text-align: center;\n  font-size: 18px;\n  background: #fff;\n\n  /* Center slide text vertically */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.swiper-slide img {\n  display: block;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
