// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text_shadow {
  text-shadow: 6px 4px 8px rgb(13, 204, 13);
}
.glass {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(10px);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Yangiliklar/news.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;AACA;EACE;;;;GAIC;EACD,2BAA2B;EAC3B,mCAAmC;AACrC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".text_shadow {\n  text-shadow: 6px 4px 8px rgb(13, 204, 13);\n}\n.glass {\n  background: linear-gradient(\n    135deg,\n    rgba(255, 255, 255, 0.1),\n    rgba(255, 255, 255, 0)\n  );\n  backdrop-filter: blur(15px);\n  -webkit-backdrop-filter: blur(10px);\n}\n\n.custom-scrollbar::-webkit-scrollbar {\n  width: 10px;\n}\n\n.custom-scrollbar::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n\n.custom-scrollbar::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 5px;\n}\n\n.custom-scrollbar::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
