// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg2 {
  background: rgb(9, 36, 121);
  background: linear-gradient(
    90deg,
    rgba(9, 36, 121, 1) 0%,
    rgba(2, 0, 36, 1) 35%,
    rgba(0, 0, 0, 0.9220063025210083) 100%
  );
}
`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B;;;;;GAKC;AACH","sourcesContent":[".bg2 {\n  background: rgb(9, 36, 121);\n  background: linear-gradient(\n    90deg,\n    rgba(9, 36, 121, 1) 0%,\n    rgba(2, 0, 36, 1) 35%,\n    rgba(0, 0, 0, 0.9220063025210083) 100%\n  );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
