import React from "react";

export default function Tuzulma() {
  return (
    <div>
      <img
        src="/assets/img/image_2024-03-22_21-57-23.png"
        alt=""
        className="w-full"
      />
    </div>
  );
}
