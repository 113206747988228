// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg_lab {
    background: rgb(68, 121, 9);
    background: linear-gradient(90deg, rgba(68, 121, 9, 0.7035189075630253) 0%, rgba(9, 121, 71, 0.7231267507002801) 52%, rgba(14, 15, 115, 0.76234243697479) 100%);
}`, "",{"version":3,"sources":["webpack://./src/pages/Labaratoriya/lab.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,+JAA+J;AACnK","sourcesContent":[".bg_lab {\n    background: rgb(68, 121, 9);\n    background: linear-gradient(90deg, rgba(68, 121, 9, 0.7035189075630253) 0%, rgba(9, 121, 71, 0.7231267507002801) 52%, rgba(14, 15, 115, 0.76234243697479) 100%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
